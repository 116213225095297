/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/fluid-properties/fluid-properties.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { environment } from 'environments/environment';

export interface GeneralFile {
  description: string;
  file: string;
  name: string;
  sub_project: string;
  uuid: string;
  nruf: number;
  is_text: boolean;
  is_small: boolean;
}
export const generalFileUrl = 'files/general-files-properties/';
export const generalFileUploadUrl = environment.restUrl + 'files/general-files-properties/upload/';
