/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/account/account.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export interface Balance {
  balance: number;
  reserved: number;
}

export interface AppAccount {
  uuid: string;
  name: string;
  balance: Balance;
  pflotranVersion: string;
}
export const accountUrl = 'account/';

export const balanceUrl = 'transactions/balance/';

export interface Transaction {
  date_created: string;
  amount: number;
  category: string;
  notes: string;
  user: string;
}
export const transactionsUrl = 'transactions/';
