/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/interfaces/queue.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export interface QueueItem {
  name: string;
  state: string;
  time: string;
  user: string;
}

export const clusterUrl = 'compute/cluster/';
export const queueUrl = clusterUrl + 'queue/';

export interface TestMachine {
  cores?: number;
  instanceType: string;
  status: string;
}

export const testMachineUrl = 'compute/test_machine/';
export const testMachineStartUrl = testMachineUrl + 'start/';
export const testMachineStateUrl = testMachineUrl + 'status/';
export const testMachineTerminateUrl = testMachineUrl + 'terminate/';
