/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/projects/projects.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export interface Project {
  description?: string;
  name?: string;
  sub_projects?: string[];
  uuid?: string;
}
export const projectsUrl = 'projects/projects/';
