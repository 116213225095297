/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/grids/grids.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { environment } from 'environments/environment';

export interface Grid {
  description: string;
  file: string;
  name: string;
  sub_project: string;
  uuid: string;
  nruf: number;
  is_text: boolean;
  is_small: boolean;
}
export const gridUrl = 'files/grids/';
export const gridUploadUrl = environment.restUrl + 'files/grids/upload/';
