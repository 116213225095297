/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/1d-plots/1d-plots.interfaces.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export interface ExternalDataVariable {
  uuid: string;
  name: string;
}

export interface ExternalDataFile {
  date_uploaded: string;
  description: string;
  name: string;
  uuid: string;
  variables?: ExternalDataVariable[];
}

export const externalDataFilesUrl = 'simulations/external_data/';

export interface PlotExternalData {
  file: ExternalDataVariable;
  xVar: ExternalDataVariable;
  yVar: ExternalDataVariable;
}

export interface Plot {
  id?: string;
  observer: string;
  observerType: string;
  xVar: string;
  yVar: string;
  title: string;
  logX: boolean;
  logY: boolean;
  dx: number;
  dy: number;
  xTitle: string;
  xType: string;  // average type
  yTitle: string;
  yType: string;
  externalData: PlotExternalData[];
}

export interface OneDPlotConfig {
  compare: string[];  // a list of uuids
  plots: Plot[];
}
