/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/interfaces/sub-projects.interfaces.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
export interface SubProject {
  description?: string;
  name?: string;
  number?: number|string;
  project?: string;
  title?: string;
  uuid?: string;
  runs?: string[];
}
export const subProjectsUrl = 'projects/sub-projects/';
